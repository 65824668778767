import{createRouter,createWebHashHistory} from "vue-router";

const routes = [
    /*主页*/
    {
        path:'/',
        name:'/',
        component:()=> import('../view/Index'),
    },

    /*主题景观*/
    {
        path: '/themeLandscape/:id?',
        name:'/themeLandscape',
        component:() => import('../view/scenicLandscape/themelandscape/ThemeLandscape'),
      /*  children:[
            {

            }
        ]*/
    },
    {
        path:'/themeLandscape/Details/:id?/:Fid?/:name?',
        name:'thDetails',
        component:() => import('../view/scenicLandscape/themelandscape/Details')
    },
    /*特色体验*/
    {
        path: '/CharacteristicExperience/:id?',
        name: '/CharacteristicExperience',
        component:() => import('../view/scenicLandscape/features/CharacteristicExperience'),
    },{
        path:'/CharacteristicExperience/Details/:Fid?/:id?/:name?',
        name:'chDetails',
        component:() => import('../view/scenicLandscape/features/Details')
    },
    /*必游景点*/
    {
        path: '/attractions/:id?',
        name: '/attractions',
        component:() => import('../view/scenicLandscape/characteristic/attractions'),
    },{
        path:'/attractions/Details/:id?/:Fid?/:name?',
        name:'attDetails',
        component:() => import('../view/scenicLandscape/characteristic/Details')
    },
    /*公共页面*/
    {
        path: '/pubView/:id?',
        name: '/pubView',
        component:() => import('../components/public/pubView'),
    },
    /*公共详情页*/
    {
        path: '/pubView/details/:id?/:Fid?/:name?',
        name: 'pubDetails',
        component:() => import('../components/public/Details'),
    },
    /*宣传视频*/
    {
        path: '/scenic/proVideo/:id?',
        name: '/scenic/proVideo',
        component:() => import('../view/scenicSpot/promotional-video'),
    },
    /*云旅游*/
    {
        path: '/cloud/tourism/:id?',
        name: '/cloud/tourism',
        component:() => import('../view/cloudTourism/cloud-tourism'),
    },
    /*在线客服*/
    {
        path: '/SC/Online/:id?',
        name: '/SC/Online',
        component:() => import('../view/serviceCenter/online-customer-service'),
    },
    /*人才招聘*/
    {
        path: '/SC/recruitment/:id?',
        name: '/SC/recruitment',
        component:() => import('../view/serviceCenter/personnel-recruitment'),
    },{
        path:'/SC/recruitment/Details/:id?/:Fid?/:name?',
        name:'reDetails',
        component:() => import('../view/serviceCenter/Details')
    },
    /*投诉建议*/
    {
        path: '/SC/complaints/:id?',
        name: '/SC/complaints',
        component:() => import('../view/serviceCenter/complaints'),
    },
    /*失物招领*/
    {
        path: '/SC/found/:id?',
        name: '/SC/found',
        component:() => import('../view/serviceCenter/lost-and-found/lost-and-found'),
    },{
        path:'/SC/found/Details/:langue?/:type?',
        name:'foundDetails',
        component:() => import('../view/serviceCenter/lost-and-found/Details')
    },
    /*吃*/
    {
        path: '/seven/eat/:id?',
        name: '/seven/eat',
        component:() => import('../view/seven/eat'),
    },
    /*住*/
    {
        path: '/seven/camping/:id?',
        name: '/seven/camping',
        component:() => import('../view/seven/camping'),
    },
    /*行*/
    {
        path: '/seven/Sightseeing/:id?',
        name: '/seven/Sightseeing',
        component:() => import('../view/seven/Coconut-Grove-parade/Coconut-Grove-parade'),
    },
    {
        path:'/seven/Sightseeing/Details/:Fid?/:id?',
        name:'sigDetails',
        component:() => import('../view/seven/Coconut-Grove-parade/Details')
    },
    {
        path:'/seven/Sightseeing/Details1/:Fid?/:id?',
        name:'cgpDetails',
        component:() => import('../view/seven/Coconut-Grove-parade/Details1')
    },
    /*购*/
    {
        path: '/seven/shopping/:id?',
        name: '/seven/shopping',
        component:() => import('../view/seven/shopping'),
    },
    /*门票套餐*/
    {
        path: '/store/:id?',
        name: '/store',
        component:() => import('../view/store/store'),
    },
    /*套餐*/
    {
        path: '/mall/:id?',
        name: '/mall',
        component:() => import('../view/store/mall'),
    },
    /*导览地图*/
    {
        path: '/navigationMap/:id?',
        name: '/navigationMap',
        component:() => import('../view/navigationMap/Details'),
    },
]

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes
})
router.afterEach(() => {
    window.scrollTo(0, 0)
});

export default router