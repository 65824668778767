<template>
  <header-view :bannerlist="bannerlist"
               :nav="nav"
               :lives="lives"/>

  <right-view :bott="bott"
              :noticeList="noticeList"
              :nav="nav"
              :lives="lives"/>

  <router-view :scenicList="scenicList"
               :noticeList="noticeList"
               :dynamicList="dynamicList"
               :activityList="activityList"
               :bott="bott"
               :nav="nav"
  />
  <bottom-view :bott="bott"
               :linklist="linklist"/>
</template>

<script>
import headerView from "@/components/public/header/Header";
import bottomView from "@/components/public/bottom/Bottom";
import rightView from "@/components/public/right/right"
import Model from '@/network/index'
export default {
  name: 'App',
  components: {
      // eslint-disable-next-line vue/no-unused-components
      headerView,
      // eslint-disable-next-line vue/no-unused-components
      bottomView,
      // eslint-disable-next-line vue/no-unused-components
      rightView,
  },
  data(){
    return{
      nav: [],//顶部导航条
      bannerlist:[],//首页轮播图列表
      scenicList:[],//必游景点轮播图列表
      noticeList:[],//景区公告列表
      dynamicList:[],//景区动态列表
      activityList:[],//景区活动列表
      bott:{},//下方信息
      linklist:[],
      lives:{},
    }
  },
  created() {
    this.init()
  },
  methods:{
    init() {
      Model.getNav(localStorage.getItem('lang')).then((res) => {
        this.nav = res.data.object.menusList
        this.bannerlist = res.data.object.bannerlist
        this.scenicList = res.data.object.scenicList
        this.noticeList = res.data.object.noticeList.slice(0,4)
        this.dynamicList = res.data.object.dynamicList.slice(0,4)
        this.activityList = res.data.object.activityList
      })
      Model.GetBott(localStorage.getItem('lang')).then((res)=>{
        this.bott = res.data.object.setting
        this.linklist = res.data.object.linklist
      })
      Model.GetTQ('469005','a67bd5a08a581f7403baacc646c8544c').then((res)=>{
        this.lives = res.data.lives[0]
      })
    },
    ToPage(str){
      this.$router.push({
        path:str
      }).then(()=>{
        this.$router.go(0)
      })
    },
  }
}
</script>

<style lang="less">
.title_name{
  width: 1200px;
  margin: 0 auto;
  padding: 15px;
  font-size: 48px;
  text-align: center;
}
.title_S_name{
  width: 1200px;
  font-size: 36px;
  text-align: center;
  font-weight: bold;
  color: rgba(47, 184, 228, 1);
  margin: 0 auto 20px;
}


*{
  margin: 0;
  padding: 0;
}
.warp {
  width: 1200px;
  margin: 0 auto;
  padding: 15px;
  background: rgba(237.15, 246.3725, 255, 1);
  .t1{
    font-size: 24px;
    text-align: center;
  }
}
.title{
  margin: 35px auto 0;
  width: 1200px;
  color: rgba(56, 56, 56, 1);
  font-size: 48px;
  cursor: default;
  user-select: none;
}
.subTitle{
  cursor: default;
  width: 1200px;
  margin: 0 auto;
  font-weight: bold;
  color: rgba(47, 184, 228, 1);
  font-size: 60px;
  user-select: none;
}

.title-middle{
  cursor: default;
  text-align: center;
  width: 1200px;
  height: 160px;
  margin: 0 auto;
  user-select: none;
  .title1{
    cursor: default;
    color: rgba(56, 56, 56, 1);
    font-size: 48px;
  }
  .subTitle1{
    cursor: default;
    font-weight: bold;
    color: rgba(47, 184, 228, 1);
    font-size: 60px;
  }
}
.breadcrumb{
  width: 1200px;
  margin: 35px auto 0;
}
.pagination{
  text-align: center;
  margin-top: 34px;
  margin-bottom: 34px;
  .el-pagination {
    justify-content: center;
  }
}
.el-pagination.is-background .el-pager li:not(.is-disabled).is-active {
  background-color: #94d499;
}


/* table 样式 */
table {
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
}
table td,
table th {
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 3px 5px;
}
table th {
  border-bottom: 2px solid #ccc;
  text-align: center;
}

/* blockquote 样式 */
blockquote {
  display: block;
  border-left: 8px solid #d0e5f2;
  padding: 5px 10px;
  margin: 10px 0;
  line-height: 1.4;
  font-size: 100%;
  background-color: #f1f1f1;
}

/* code 样式 */
code {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  background-color: #f1f1f1;
  border-radius: 3px;
  padding: 3px 5px;
  margin: 0 3px;
}
pre code {
  display: block;
}

/* ul ol 样式 */
ul, ol {
  margin: 10px 0 10px 20px;
}

/*移入放大*/
.fangda{
  cursor: pointer;
  transition: all 0.6s;
  overflow: hidden;
}
.fangda img{
  cursor: pointer;
  transition: all 0.6s;
}

.fangda:hover img{
  transform: scale(1.2);
}

</style>
