<template>
  <div :lang="lang" class="main">
    <div class="main_item" @mouseenter="enter('id1')" @mouseleave="leave('id1')">
      <img :src="require('@/assets/imgs/right/公告.png')"><p style="margin-top: 4px">{{$t('messages.right.p1')}}</p>
      <!-- 公告 -->
      <div id="id1" class="gongGao public hidd">
        <template v-if="noticeList.length > 0">
          <div class="gongGao-main">
            <div class="div1" v-for="(item,index) in noticeList.slice(0,3)" :key="index">
              <router-link :to="{name:'pubDetails',
                                  params:{id:item.id,Fid:5}}"
                           @click.prevent="ToPage('pubDetails',item.id,link)">
                <p class="pp">{{item.title}}</p>
<!--                <span>{{ new Date(item.createtime).getFullYear()
                + '-' + (new Date(item.createtime).getMonth()+1) + '-'
                + new Date(item.createtime).getDate()}}</span>-->
              </router-link>
            </div>
          </div>
        </template>
       <template v-else>
         <div class="gongGao-main">
           <div class="div1" style="line-height: 55px;font-size: 36px">
              NO DATA
           </div>
         </div>
       </template>
      </div>
    </div>
    <div class="main_item" @mouseenter="enter('id2')" @mouseleave="leave('id2')">
      <img :src="require('@/assets/imgs/right/电话.png')"><p style="margin-top: 4px">{{$t('messages.right.p2')}}</p>
      <!-- 电话 -->
      <div id="id2" class="dianHua public hidd">
        <div class="dianHua-main ">
          <div><p>园区：{{bott.phone}}</p></div>
          <div><p>服务：{{bott.servephone}}</p></div>
        </div>
      </div>
    </div>
    <div class="main_item" @mouseenter="enter('id3')" @mouseleave="leave('id3')" >
      <img :src="require('@/assets/imgs/right/公众号.png')"><p style="margin-top: 4px">{{$t('messages.right.p3')}}</p>
      <!-- 公众号 -->
      <div id="id3" class="gongZhongHao public hidd">
        <div class="main-left"><p>{{$t('messages.right.p3')}}</p></div>
        <div class="main-right"><img :src="basURL + bott.wxQrcode"></div>
      </div>
    </div>
    <div class="main_item" @mouseenter="enter('id4')" @mouseleave="leave('id4')">
      <img :src="require('@/assets/imgs/right/新浪微博.png')"><p style="margin-top: 4px">{{$t('messages.right.p4')}}</p>
      <!-- 微博 -->
      <div id="id4" class="weiBo public hidd">
        <div class="main-left"><p>{{$t('messages.right.p4')}}</p></div>
        <div class="main-right"><img :src="basURL + bott.wbQrcode"></div>
      </div>
    </div>
    <div class="main_item" @mouseenter="enter('id5')" @mouseleave="leave('id5')"  >
      <img :src="require('@/assets/imgs/right/抖音.png')"><p style="margin-top: 4px">{{$t('messages.right.p5')}}</p>
      <!-- 抖音 -->
      <div id="id5" class="douYin public hidd">
        <div class="main-left"><p>{{$t('messages.right.p5')}}</p></div>
        <div class="main-right"><img :src="basURL + bott.dyQrcode"></div>
      </div>
    </div>
    <div class="main_item" @mouseenter="enter('id6')" @mouseleave="leave('id6')">
      <img :src="require('@/assets/imgs/right/承载量.png')"><p style="margin-top: 4px">{{$t('messages.right.p6')}}</p>
      <!-- 承载量 -->
      <div id="id6" class="ChengZai hidd">
        <div class="left">
          <img class="img1" :src="require('@/assets/imgs/承载量图片.jpg')">
          <div class="div01">
            <div>

            </div>
            <div>
             <p class="p1">{{new Date().getDate()}}</p>
             <p class="p2">{{new Date().getUTCMonth()+ 1 + ' '}}{{ $t('messages.time.t2') + ' '}}{{new Date().getFullYear()}}</p>
             <div class="p3">
                <span> {{lives.weather}}</span>
                <span> {{lives.temperature}}℃</span>
                <img :src="require('@/assets/imgs/天气测试.png')">
             </div>
            </div>
          </div>
        </div>
        <div class="right">
            <p class="p1">
              {{ new Date().getFullYear()+' '
            + $t('messages.time.t1') + ' ' + (new Date().getMonth()+1) + ' ' + $t('messages.time.t2')
            + ' ' + new Date().getDate() + ' ' + $t('messages.time.t3')}}
            </p>
            <p class="p2">{{$t('messages.ses.c7')}} <span>10000 {{$t('messages.ses.a4')}}</span></p>

          <div class="box01 div00">
            <div class="sanjiao"></div>
            <div class="div000">
              <span class="sp1">{{$t('messages.ses.c8')}}</span>
            </div>

            <div class="sp_right">
              <template v-for="(item,index) in JSON.stringify(this.num)" :key="index">
                <div class="box001">
                  <span class="sp2">{{item}}</span>
                </div>
              </template>
            </div>
          </div>

          <div class="box02 div00">
            <div class="sanjiao"></div>
            <div class="div000">
              <span class="sp1">{{$t('messages.ses.c9')}}</span>
            </div>
            <div class="sp_right">
              <template v-for="(item,index) in JSON.stringify(this.num1)" :key="index">
                <div class="box001">
                  <span class="sp2">{{item}}</span>
                </div>
              </template>
            </div>
          </div>
          <div class="box03 div00">
            <div class="sanjiao"></div>
            <div class="div000">
              <span class="sp1">{{$t('messages.ses.c10')}}</span>
            </div>
            <div class="sp_right">
              <template v-for="(item,index) in JSON.stringify(this.num2)" :key="index">
                <div class="box001">
                  <span class="sp2">{{item}}</span>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <el-backtop class="topp" :right="110" :bottom="50" >
    <div>
      TOP
    </div>
  </el-backtop>
</template>

<script>
import Model from '@/network/index'
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "right",
  props:['bott','noticeList','nav','lives'],
  data() {
    return {
      basURL:'https://hh.elutrip.cn/',
      link:0,
      lang:localStorage.getItem('lang'),
      num: 0,
      num1: 0,
      num2: 0,
    }
  },
  created() {
    this.getLink()
  },
  mounted() {
    this.getPassenger()
  },
  methods:{
    enter(id){
      if (    document.getElementById(id).classList.contains('hidd')){
        if (  document.getElementById(id).classList.contains('slide-out-right'))
              document.getElementById(id).classList.remove('slide-out-right')

              document.getElementById(id).classList.add('slide-in-right')
              document.getElementById(id).classList.remove('hidd')
      }
    },
    leave(id){
      if (  !document.getElementById(id).classList.contains('hidd')){
            document.getElementById(id).classList.add('slide-out-right')
            document.getElementById(id).classList.remove('slide-in-right')
            window.setTimeout(()=>{
            document.getElementById(id).classList.add('hidd')
        },200)
      }
    },
    ToPage(str, id, Fid) {
      this.$router.push({
        name: str,
        params: {
          id: id,
          Fid: Fid,
        }
      }).then(() => {
        this.$router.go(0)
      })
    },
    getLink(){
      for (let i = 0; i < this.nav.length; i++) {
        if (this.nav[i].sign === 'item2')
          for (let j = 0; j <this.nav[i].smenusList.length; j++) {
            if (this.nav[i].smenusList[j].sign === '/notice')
              this.link = this.nav[i].smenusList[j].id
          }
      }
    },
    getPassenger(){
      Model.getPassenger().then(res =>{
            this.num = res.data
            this.num1 = Math.round(this.num + (res.data*0.4))
            this.num2 = Math.round(this.num1 + (res.data*0.5))
      })
    },
  }
}
</script>

<style lang="less" scoped>
  .main{
    z-index: 100;
    position: fixed;
    right: 0;
    bottom: 50px;
    margin: auto;
    width: 80px;
    height:580px;
    background: rgba(88, 172, 54.99999999999999, 1);
    border-radius: 4px 0 0 4px;
    user-select: none;

    .main_item{
      position: relative;
      width: 80px;
      height: 85px;
      color: white;
      margin-top: 10px;
      img{
        width: 40px;
        height: 40px;
      }
      text-align: center;
      /*公共部分*/
      .public{
        position: fixed;
        width: 240px;
        height: 100px;
        right: 80px;
        border-radius: 4px;
        background: url('/src/assets/imgs/right/背景.png') no-repeat;
      }
      .main-left{
        width: 72px;
        height: 36px;
        text-align: center;
        margin-top: 32px;
        margin-left: 32px;
        p{
          color: rgba(255, 255, 255, 1);
          font-size: 24px;
          font-weight: bold;
          margin: 0 auto;
        }
      }
      .main-right{
        width: 90px;
        height: 90px;
        margin-top: 5px;
        margin-left: 14px;
        img{
          height: 90px;
          width: 90px;
          margin: 0 auto;
        }
      }
      /*公共部分END*/
      /*公告部分*/
      .gongGao{
        bottom: 530px;
        .gongGao-main{
          width: 240px;
          height: 79px;
          margin-top: 11px;
          margin-left: 20px;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-bottom: 8px;
          font-size: 14px;
          color: white;
          a{
            text-decoration: none;
            color: white;
            p{
              overflow: hidden;
              display: inline-block;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 200px;
            }
            span{
              float: right;
            }
          }
          .div1{
            width: 200px;
            height: 21px;
            margin-bottom: 8px;
          }
        }
      }
      /*电话部分*/
      .dianHua{

        bottom: 450px;
        .dianHua-main{
          user-select: text;
          width: 207px;
          height: 62px;
          margin-top: 19px;
          margin-left: 17px;
          div{
            color: white;
            width: 207px;
            height: 27px;
            margin-bottom: 8px;
            p{
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              color: rgba(255, 255, 255, 1);
              font-size: 17px;
            }
          }
        }
      }
      /*公众号*/
      .gongZhongHao{
        bottom: 350px;
        display: flex;
      }
      /*微博*/
      .weiBo{
        bottom: 250px;
        display: flex;
      }
      /*抖音*/
      .douYin{
        bottom: 150px;
        display: flex;
      }
      /*承载量*/
      .ChengZai{
        bottom: 50px;
        display: flex;
        position: fixed;
        width: 1020px;
        height: 510px;
        right: 80px;
        z-index: 100;
        .left{
          position: relative;
          width: 510px;
          height: 510px;
          .img1{
            display: block;
            position: absolute;
            object-fit: cover;
            width: 510px;
            height: 510px;
          }
          .div01{
            position: absolute;
            margin-top: 18px;
            margin-left: 18px;
            width: 470px;
            height: 470px;
            border: 2px solid rgba(255, 255, 255, 1);
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.19);
            display: flex;
            div{
              .img2{
                width: 166px;
                height: 117px;
              }
              .p1{
                text-align: right;
                margin-top: 22px;
                margin-right: 18px;
                font-size: 36px;
                font-weight: bold;
                color: #000;
              }
              .p2{
                text-align: right;
                margin-right: 18px;
                margin-top: 10px;
                font-size: 20px;
                color: #000;
              }
              .p3{
                margin-right: 18px;
                margin-top: 20px;
                color: #000;
                span{
                  font-size: 20px;
                  vertical-align: middle;
                  margin-right: 10px;
                }
                img{
                  vertical-align: middle;
                }
              }
            }
            justify-content: space-between;
          }
        }
        .right{
          width: 510px;
          height: 510px;
          background-color: white;
          .p1{
            margin-top: 29px;
            margin-left: 31px;
            text-align: left;
            font-size: 20px;
            color: rgba(112, 112, 112, 1);
          }
          .p2{
            margin-top: 20px;
            margin-left: 31px;
            text-align: left;
            font-size: 18px;
            font-weight: bold;
            color: rgba(112, 112, 112, 1);
            span{
              color: rgba(88, 172, 55, 1);
            }
          }
          .div00{
            position: relative;
            width: 460px;
            height: 100px;
            margin-top: 20px;
            margin-left: 31px;
            border-radius: 4px;
            border: 1px solid rgba(166, 166, 166, 1);
            display: flex;
            .sp1{
              line-height: 100px;
              font-size: 20px;
              font-weight: bold;
            }
            .sp_right{
              position: absolute;
              display: flex;
              right: 0;
              .box001{
                width: 24px;
                margin-top: 0;
                text-align: center;
                height: 40px;
                margin-right: 10px;
                line-height: 100px;
                .sp2{
                  width: 100%;
                  border: 1px solid rgba(166, 166, 166, 1);
                  height: 100%;
                  display: inline-block;
                  line-height: 40px;
                  color: black;
                  background-color: white;
                  letter-spacing: 3px;
                  font-size: 20px;
                }
              }
            }
          }
          .box01{
            background: rgba(88, 172, 55, 1);
          }
          .box02{
            background: rgba(229, 229, 229, 1);
            .sp1{
              color: rgba(112, 112, 112, 1);
            }
          }
          .box03{
            background: rgba(229, 229, 229, 1);
            .sp1{
              color: rgba(112, 112, 112, 1);
            }
          }
        }
      }
    }
  }

  .main:lang(en){
    .main_item{
      /*公共部分*/
      .main-left{
        p{
          font-size: 20px;
        }
      }
      /*公共部分END*/
      /*承载量*/
      .ChengZai{
        .right{
          .div00{
            .div000{
              display: flex;
              width: 200px;
              align-items: center;
              .sp1{
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }
  .main:lang(ru){
    width: 100px;
    .main_item{
      width: 100px;
      left: 0;
      right: 0;
      margin: 10px auto;

      /*公共部分*/
      .public{
        right: 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: center;
      }
      .main-left{
        margin-top: 0;
        margin-left: 10px;
        margin-right: 20px;
        p{
          font-size: 18px;
        }
      }
      .main-right{
        margin-left: 10px;
        margin-right: 14px;
      }
      /*公共部分END*/
      /*电话部分*/
      .dianHua{
        .dianHua-main{
          margin-top: 0;
          div{
            p{
              font-size: 20px;
            }
          }
        }
      }
      /*承载量*/
      .ChengZai{
         right: 100px;
        .right{
          .div00{
            .div000{
              display: flex;
              width: 200px;
              align-items: center;
              .sp1{
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }
  .sanjiao {
    text-align: center;
    width: 0px;
    height: 0px;
    margin-left: 30px;
    margin-top: 41px;
    overflow: hidden;
    border-width: 9px;
    border-color:  transparent transparent transparent rgba(56, 56, 56, 1);
    border-style: solid dashed dashed dashed;
  }
.topp{
  width: 75px;
  height: 75px;
  background: rgba(88, 172, 54.99999999999999, 1);
  div{
    color: white;
    font-weight: bold;
  }
}
.topp:hover{
  background: rgba(88, 172, 54.99999999999999, 1);
}
.hidd{
    opacity: 0;
    visibility: hidden;
  }

  @media screen and (max-width: 1440px){
    .main{
      right: 0;
      bottom: 25px;
      width: 80px;
      height: 560px;
      div{
        width: 80px;
        height: 60px;
        color: white;
        img{
          width: 25px;
          height: 25px;
        }
        /*公共部分*/
        .public{
          right: 80px;
        }
        /*公共部分END*/
        /*公告部分*/
        .gongGao{
          bottom: 485px;
        }
        /*电话部分*/
        .dianHua{
          bottom: 390px;
        }
        /*公众号*/
        .gongZhongHao{
          bottom: 300px;
        }
        /*微博*/
        .weiBo{
          bottom: 200px;
        }
        /*抖音*/
        .douYin{
          bottom: 100px;
        }
        /*承载量*/
        .ChengZai{
          bottom: 25px;
        }
      }
    }
  }

  /*移入动画*/
  .slide-in-right {
    -webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
  /* ----------------------------------------------
 * Generated by Animista on 2022-4-11 10:16:28
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
   * ----------------------------------------
   * animation slide-in-right
   * ----------------------------------------
   */
  @-webkit-keyframes slide-in-right {
    0% {
      -webkit-transform: translateX(25px);
      transform: translateX(25px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-right {
    0% {
      -webkit-transform: translateX(25px);
      transform: translateX(25px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }
/*移入动画END*/

  /*移出动画*/
  .slide-out-right {
    -webkit-animation: slide-out-right 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: slide-out-right 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  }
  /* ----------------------------------------------
 * Generated by Animista on 2022-4-11 10:28:2
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
   * ----------------------------------------
   * animation slide-out-right
   * ----------------------------------------
   */
  @-webkit-keyframes slide-out-right {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(25px);
      transform: translateX(25px);
      opacity: 0;
    }
  }
  @keyframes slide-out-right {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(25px);
      transform: translateX(25px);
      opacity: 0;
    }
  }

  /*移出动画 END*/
</style>