import {request} from "@/network/axios"
import {post} from "@/network/ticket";


class Model{
//票务
    //获取门票列表
   async getList(data) {
       return await post("TjApi/Tickets", data)
   }
   //官网门票下单
    async OrderGW(params) {
        return await post("TjApi/OrderGW", params)
    }
    async finally(params){
       return await post("Tjapi/Notifys",params)
    }
//票务 -END-

    //获取导航条
   getNav(lang){
        return request({
            url:'thewebsite/getHomePage',
            method:'GET',
            params:{langue:lang}
        })
   }
   //获得内容列表
    getContentList(id,sortType,pageNumber,pageSize,paramStr){
        return request({
            url:'thewebsite/getContentByType',
            method:'GET',
            params:{id:id,sortType:sortType,pageNumber:pageNumber,pageSize:pageSize,paramStr:paramStr}
        })
   }
   //获得详情页
    getDetails(id){
        return request({
            url:'thewebsite/getByContentId',
            method:'GET',
            params:{id:id}
        })
    }
    //失物招领
    getLostFound(langue,type){
        return request({
            url:'thewebsite/getLostFound',
            method:'GET',
            params:{
                langue,
                type
            }
        })
    }
    //投诉建议
    sendComplaint(data){
        return request({
            url:'thewebsite/sendComplaint',
            method:'POST',
            data: data
        })
    }

    GetColumn(langue,sign,type){
        return request({
            url:'thewebsite/getColumnBySign',
            method:'GET',
            params:{
                langue,
                sign,
                type
            }
        })
    }
    GetColumnFor(langue,sign,sortType,type){
        return request({
            url:'thewebsite/getMenusBySign',
            method:'GET',
            params:{
                langue,
                sign,
                sortType,
                type
            }
        })
    }

    GetBott(langue){
       return request({
           url:'thewebsite/getWebsiteSetting',
           method:'GET',
           params:{
               langue
           }
       })
    }

    GetTQ(city,key){
       return request({
           url:'https://restapi.amap.com/v3/weather/weatherInfo',
           method:'GET',
           params:{
               city,
               key
           }
       })
    }
    getPassenger(){
        return request({
            url:'PassengerFlow/getPassenger',
            method:'GET',
        })
    }
}

export default new Model();