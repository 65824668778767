<template>
  <div :lang="lang">
    <div class="shang">
        <div class="one">
          <router-link :to="{name:'/'}">
            <img :src="basURL+ bott.logo">
          </router-link>
        </div>
        <div class="tow">
          <p>{{$t('messages.butt.p1')}}</p>
          <p>{{bott.websiteName}}</p>
        </div>
        <div class="three">
          <img :src="basURL+ bott.wxQrcode">
        </div>
        <div class="four">
          <p>{{$t('messages.butt.add')}}{{bott.address}}</p>
          <p>{{$t('messages.butt.tel')}}{{bott.phone}}</p>
          <p>{{$t('messages.butt.copyright')}}{{bott.copyright}}</p>
        </div>
        <div class="five">
            <p>{{$t('messages.butt.tit')}}</p>
          <template v-for="(item,index) in linklist" :key="index">
            <a target="_blank" :href="item.tourl" >{{item.name}}</a><span v-if="index !== linklist.length-1">|</span>
          </template>
        </div>
    </div>

    <div class="xia">

      <span>
      <a target="_blank" style="color:white;text-decoration: none"
              href="https://beian.miit.gov.cn/#/Integrated/index">
         {{bott.code}}</a>
        <span style="margin-left: 20px;margin-right: 20px">|</span>
       <a target="_blank" style="color:white;text-decoration: none"
          href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=46010802001076">
         <img :src=" require('/src/assets/imgs/备案图标.png')" style="vertical-align: middle"> 琼公网安备 46010802001076号</a>
      <span style="margin-left: 20px;margin-right: 20px">|</span>
        {{$t('messages.butt.span2')}}
        {{bott.technicalSupport}}</span>


    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Bottom",
  props:['bott','linklist'],
  data(){
    return{
      basURL:'https://hh.elutrip.cn/',
      lang:localStorage.getItem('lang')
    }
  }
}
</script>

<style lang="less" scoped>
div {
  height: 188px;
  .shang {
    height: 140px;
    background: rgba(68.00000000000001, 134, 73.00000000000001, 1);
    display: flex;
      .one{
        width: 198px;
        height: 140px;
        margin-left: 360px;
        img{
          width: 189px;
          height: 140px;
        }
      }

      .tow{
        width: 115px;
        height: 140px;
        margin-top: 25px;
        p{
          font-weight: bold;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
        }
      }

      .three{
        width: 90px;
        height: 90px;
        margin-top: 25px;
        margin-left: 10px;
        img{
          width: 90px;
          height: 90px;
        }
      }

      .four{
        margin-top: 25px;
        margin-left: 10px;
        height: 115px;
        p{
          margin-bottom: 14px;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
        }
      }

      .five{
        margin-top: 25px;
        margin-left: 10px;
        height: 115px;
        p{
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          font-weight: bold;
        }
        span{
          padding: 0 8px 0 8px;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
        }
        a{
          color: white;
          text-decoration: none;
        }
      }
    }

  .xia {
    height: 48px;
    background: rgba(43.99999999999999, 109.99999999999999, 48.99999999999999, 1);
    text-align: center;
    line-height: 48px;
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
  }
}
div:lang(en) {
  .shang {
    height: 140px;
    display: flex;
      .one{
        margin-left: 160px;
      }
      .tow{
        width: 200px;
      }
      .three{
        margin-left: 0;
      }
      .four{
        width: 600px;
        margin-left: 20px;
        p{
          margin-bottom: 5px;
        }
      }
      .five{
        width: 500px;
        margin-left: 0;
      }
    }
}
div:lang(ru) {
  .shang {
    height: 140px;
    display: flex;
      .one{
        margin-left: 160px;
      }
      .tow{
        width: 200px;
        p{
          font-weight: bold;
          color: rgba(255, 255, 255, 1);
          font-size: 18px;
        }
      }
      .three{
        margin-left: 0;
      }
      .four{
        width: 600px;
        margin-left: 20px;
        margin-top: 20px;
        p{
          margin-bottom:1px;
          font-size: 18px;
        }
      }
      .five{
        margin-top: 20px;
        width: 460px;
        margin-left: 0;
        p{
          font-size: 20px;
        }
        span{
          font-size: 26px;
        }
      }
    }
}


@media screen and (max-width: 1440px){
  div {
    .shang {
      .one{
        margin-left: 200px;
      }
      .five{
        margin-top: 25px;

        width: 284px;
        height: 115px;
        p{
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          font-weight: bold;
        }
        span{
          padding: 0 8px 0 8px;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
        }
        a{
          color: white;
          text-decoration: none;
        }
      }
    }
  }
}
@media screen and (max-width: 1366px){
  div {
    .shang {
      .one{
        margin-left: 150px;
      }
      .five{
        margin-top: 25px;

        width: 284px;
        height: 115px;
        p{
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          font-weight: bold;
        }
        span{
          padding: 0 8px 0 8px;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
        }
        a{
          color: white;
          text-decoration: none;
        }
      }
    }
  }
}
@media screen and (max-width: 1280px){
  div {
    .shang {
      .one{
        margin-left: 50px;
      }
    }
  }
}

</style>