<template>
  <div class="header">
    <div>
      <swiper
          class="top-swiper"
          :modules="modules"
          :loop="true"
          :autoplay="{
           delay: 3000,//切换时间
           disableOnInteraction: false,
        }"
      >
        <swiper-slide class="slide" v-for="(item,index) in bannerlist" :key="index">
            <img :src="basURL + item.imageurl"/>
        </swiper-slide>
      </swiper>
    </div>
    <!--  导航条  -->
    <nav :lang="lange" class="hl_nav">
      <div class="logo">
        <router-link :to="{name:'/'}">
          <img :src="require('/src/assets/logo.png')">
        </router-link>
      </div>
      <div class="list">
        <div  class="item item-one" style=" cursor: default ;" v-for=" i in nav" :key="i.id">
          <template v-if="i.smenusList.length <= 0">
            <router-link :to="{name:i.tourl, params:{id:i.id}}" @click.prevent="GO(i)">
              <div class="sp"> {{ i.title }}</div>
            </router-link>
          </template>
          <template v-else>
              <p class="sp">{{ i.title }}</p>
          </template>
          <div v-if="!(i.smenusList.length <= 0)" class="shade">
            <div class="item-ul" :id="i.sign">
              <ul>
                <li v-for="j in i.smenusList" :key="j.id">
                  <router-link v-if="j.tourl" :to="{name:j.tourl, params:{id:j.id}}" @click.prevent="GO(j)">{{ j.title }}</router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!--语言-->
      <div class="dropdown">
        <span>
            {{ $t('messages.lan') }}
        </span>
        <div class="di">
          <ul>
            <li>
              <a style="cursor: pointer" @click="changeLang('cn')">中文</a>
            </li>
            <li>
              <a  style="cursor: pointer" @click="changeLang('en')">English</a>
            </li>
            <li>
              <a style="cursor: pointer" @click="changeLang('ru')">русский</a>
            </li>
          </ul>

        </div>
        <div class="sanjiao"> </div>
      </div>
      <div class="TianQi">
        <img :src="require('@/assets/imgs/天气测试.png')"/>
        <span>{{lives.temperature}} °C {{lives.weather}}</span>
      </div>

    </nav>

    <div :lang="lange" class="imgs">
      <p class="p1">{{ $t('messages.title') }}</p>
      <p class="p2">{{ $t('messages.EN_title') }}</p>
    </div>

    <div :lang="lange" class="boot">
      <!--吃-->
      <a @click.prevent="GoForSing('/food',2)">
        <div class="fangda" style="background: linear-gradient(180deg, rgba(0, 204, 255, 1) 0%, rgba(102, 153, 255, 1) 100%);"><img
            :src="require('/src/assets/imgs/icon/吃.png')">
          <p>{{ $t('messages.seven.one') }}</p></div>
      </a>
      <!--住-->
      <a @click.prevent="GoForSing('/camp',2)">
        <div class="fangda"  style="background: linear-gradient(rgb(0,204,255),rgb(153,204,255))"><img
            :src="require('/src/assets/imgs/icon/住.png')">
          <p>{{ $t('messages.seven.tow') }}</p></div>
      </a>
      <!--行-->
      <a @click.prevent="GoForSing('/travel',2)">
        <div class="fangda"  style="background: linear-gradient(rgb(149,0,255),rgb(255,51,153))"><img
            :src="require('/src/assets/imgs/icon/行.png')">
          <p>{{ $t('messages.seven.three') }}</p></div>
      </a>
      <!--游-->
      <a @click.prevent="GoForSing('/spot',1)">
        <div class="fangda"  style="background: linear-gradient(rgb(255,153,51),rgb(255,255,0))"><img
            :src="require('/src/assets/imgs/icon/游.png')">
          <p>{{ $t('messages.seven.four') }}</p></div>
      </a>
      <!--购-->
      <a @click.prevent="GoForSing('/shopping',2)">
        <div class="fangda"  style="background: linear-gradient(rgb(0,153,51),rgb(153,255,0))"><img
            :src="require('/src/assets/imgs/icon/购.png')">
          <p>{{ $t('messages.seven.five') }}</p></div>
      </a>
      <!--娱-->
      <a @click.prevent="GoForSing('/chara',1)">
        <div class="fangda"  style="background: linear-gradient(#7594f6,#9b39e2)"><img
            :src="require('/src/assets/imgs/icon/娱.png')">
          <p>{{ $t('messages.seven.six') }}</p></div>
      </a>
      <!--学-->
      <a @click.prevent="GoForSing('/pubV',1)">
        <div class="fangda"  style="background: linear-gradient(#23d5df,#7426ec)"><img
            :src="require('/src/assets/imgs/icon/学.png')">
          <p>{{ $t('messages.seven.seven') }}</p></div>
      </a>
    </div>

  </div>

</template>

<script>
import {ref} from 'vue'
import {useI18n} from 'vue-i18n'
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import 'swiper/css';
import  {Autoplay} from 'swiper';
import Model from '@/network/index'
import { useRouter } from 'vue-router'
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Header",
  props:['bannerlist','nav','lives'],
  data() {
    return {
      basURL:'https://hh.elutrip.cn/',
      lange: localStorage.getItem('lang'),
    }
  },
  components:{
    Swiper,
    SwiperSlide,
  },
 /* created() {
    this.getNav()
  },*/
  methods: {
    GO(i){
      this.$router.push({
        name:i.tourl,
        params:
            {id:i.id}
      }).then(()=>{
        this.$router.go(0)
      })
    },

    GoForSing(sing,type){
      Model.GetColumn(localStorage.getItem('lang'),sing,type).then((res)=>{
        this.$router.push({
          name:res.data.object.columnMenus.tourl,
          params:
              {id:res.data.object.columnMenus.id}
        }).then(()=>{
          this.$router.go(0)
        })
      })
    },
  },

  setup() {
    //下面这行代码要写在所有代码前面
    const {locale} = useI18n()
    //如果在ref / reactive中使用

    //最好这样使用
    let lang = ref('messages.lan')
    //let lang = ref(t('message.string_lang')) 这种方式目前我测试  在语言切换后页面上达不到响应式
    if (!localStorage.getItem('lang')) {
      localStorage.setItem('lang', 'cn')
    }
    const route = useRouter();
    //语言切换
    const changeLang = (lang) => {
      //切换语言 值要对应上面index中导出的名字  要一致  第四步
      locale.value = lang
      //缓存到localStorage中下次进来还是切换后的语言  如果不需要可以删除下面代码
      localStorage.setItem('lang', lang)
      route.push({path:'/'}).then(()=>{
        location.reload()
      })
 /*     Model.getNav(localStorage.getItem('lang')).then((res) => {
        // eslint-disable-next-line vue/no-mutating-props
             this.nav = res.data.object.menusList
      })
      location.reload()*/
    }

    return {
      lang,
      changeLang,
      modules: [Autoplay],
    }
  },
}
</script>

<style lang="less" scoped>

//页面
.header {
  position: relative;
  height: 720px;
/*  background: url("/src/assets/imgs/头部.png") no-repeat;*/
 /* background-size: 100% 100%;*/

  .top-swiper {
    z-index: 0;
    position: absolute;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

}
.imgs {
  position: absolute;
  z-index: 100;
  width: 1200px;
  height: 154px;
  left: 360px;
  user-select: none;
  pointer-events: none;
  top: 290px;
  .p1 {
    font-family: '迷你简毡笔黑', serif;
    text-shadow: 0px 2px 18px rgba(36, 110, 163, 0.5);
    color: rgba(255, 255, 255, 1);
    font-size: 96px;
  }

  .p2 {
    text-shadow: 0px 2px 18px rgba(36, 110, 163, 0.5);
    color: rgba(255, 255, 255, 1);
    font-size: 36px;
  }
}
.imgs:lang(ru) {
  .p1 {
    font-family: '';
  }
}


//下方的七个链接
.boot {
  position: absolute;
  z-index: 100;
  width: 1200px;
  height: 160px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: space-between;

  a {
    cursor: pointer;
    div {
      display: inline-block;
      width: 160px;
      height: 160px;
      text-align: center;
      border-radius: 5px;

      img {
        margin-top: 24px;
        width: 70px;
        height: 70px;
      }

      p {
        color: white;
        font-size: 33px;
      }
    }
  }
}

.boot:lang(en) {
  a {
    div {
      display: inline-block;
      width: 160px;
      height: 160px;
      text-align: center;
      border-radius: 5px;

      img {
        margin-top: 24px;
        width: 70px;
        height: 70px;
      }

      p {
        color: white;
        font-size: 25px;
        white-space:normal;
        word-break:break-all;
      }
    }
  }
}
.boot:lang(ru) {
  a {
    div {
      display: inline-block;
      width: 160px;
      height: 160px;
      text-align: center;
      border-radius: 5px;

      img {
        margin-top: 24px;
        width: 70px;
        height: 70px;
      }

      p {
        color: white;
        font-size: 28px;
        white-space:normal;
        word-break:break-all;
      }
    }
  }
}


/*导航栏 */
.hl_nav {
  position: relative;
  color: black;
  font-weight: bold;
  height: 120px;
  background-color: rgba(255, 255, 255, 0.4);
  box-shadow: 0px 2px 23px rgba(122, 151, 158, 0.5);
  z-index: 1;
  display: flex;

  .logo {
    position: absolute;
    height: 119px;
    width: 168px;
    top: 2px;
    left: 89px;

    img {
      height: 119px;
      width: 168px;
    }
  }

  .list {
    width: 1255px;
    height: 120px;
    display: flex;
    margin-left: 289px;

    .item {
      width: 148px;
      height: 120px;
      line-height: 120px;
      text-align: center;
      color: rgba(56, 56, 56, 1);
      font-size: 20px;

      a {
        display: block;
        text-decoration: none;
        color: rgba(56, 56, 56, 1);
      }
    }
    .sp {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .item:hover {
      background-color: rgba(255, 255, 255, 0.6);
      color: rgba(48, 184, 228, 1);
    }

    .item-one:hover .shade {
      visibility: visible;
      opacity: 1;
      transition-delay: 0s;
    }
  }


  #item1{
   left: 410px;
  }
  #item2{
   left: 680px;
  }
  #item3{
   left: 960px;
  }
  #item4{
   left: 1240px;
  }
  #item5{
    left: 550px;
  }

  .item-ul {
    position: absolute;
    width: auto;
    height: 64px;
    line-height: 64px;
    a {
      text-decoration: none;
      color: rgba(56, 56, 56, 1);
      font-size: 16px;
    }

    li {
      float: left;
      list-style: none;
      margin-right: 30px;
      margin-left: 45px;
    }

    a:hover {
      color: rgba(48, 184, 228, 1);
      text-decoration: underline;
    }
  }

  .TianQi {
    position: relative;
    top: 37px;
    height: 48px;
    left: 50px;
    cursor: default;
    user-select: none;

    img {
      height: 48px;
      width: 48px;
      vertical-align: middle;
    }

    span {
      vertical-align: middle;
    }
  }
}

.hl_nav:lang(en){
  .logo {
    top: 0;
    left: 0;
  }

  .list {
    width: 1400px;
    margin-left: 168px;
    .item {
      display: flex;
      width: 175px;
      line-height: 20px;
      text-align: center;
      font-size: 18px;
      justify-content: center; /* 水平居中 */
      align-items: center;     /* 垂直居中 */
    }
    .sp {
      width: 143px;
      height: 35px;
    }
  }
  #item1{
    left: 295px;
  }
  #item2{
    left: 695px;
  }
  #item3{
    left: 880px;
  }
  #item4{
    left: 1360px;
  }
}

.hl_nav:lang(ru){
  .logo {
    top: 0;
    left: 0;
  }

  .list {
    width: 1400px;
    margin-left: 168px;
    .item {
      display: flex;
      width: 175px;
      line-height: 20px;
      text-align: center;
      font-size: 20px;
      justify-content: center; /* 水平居中 */
      align-items: center;     /* 垂直居中 */
    }
    .shade{
      .item-ul{
        a{
          font-size: 22px;
        }
      }
    }

    .sp {
      width: 143px;
      height: 85px;
    }
  }
  #item1{
    left: 300px;
  }
  #item2{
    left: 660px;
  }
  #item3{
    left: 700px;
  }
  #item4{
    left: 1250px;
  }
  .dropdown {
    span{
      font-size: 24px;
    }
  }
}

.shade {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.3s, opacity 0.3s linear;

  height: 64px;
  top: 120px;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.4);
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.6);
  filter: alpha(opacity=90);
  border-bottom: #ffffff solid 1px;
  border-bottom: rgba(255, 255, 255, 0.3) solid 1px;
  color: rgba(56, 56, 56, 1);
}

/* 语言栏*/
.dropdown {
  position: relative;
  cursor: pointer;
  width: 135px;
  height: 120px;
  text-align: center;
  color: rgba(56, 56, 56, 1);
  font-size: 20px;
  line-height: 120px;
  display: flex;
  span{
    margin-left: 10px;
  }
  .sanjiao {
    text-align: center;
    width: 0px;
    height: 0px;
    margin-left: 5px;
    margin-top: 55px;
    overflow: hidden;
    border-width: 10px;
    border-color: rgba(56, 56, 56, 1) transparent transparent  transparent ;
    border-style: solid dashed dashed dashed;
  }
}

.di {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.3s, opacity 0.3s linear;

  position: absolute;
  top: 110px;
  left: -20px;
}

.di ul {
  list-style: none;
  background-color: rgba(255, 255, 255, 0.6);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  color: rgba(56, 56, 56, 1);
  font-size: 24px;

  a {
    text-decoration: none;
  }
}

.di ul li {
  width: 135px;
  border: #a6a6a6;
  border-top-style: solid;
  height: 48px;
  line-height: 48px;

  a {
    color: rgba(56, 56, 56, 1);
    font-size: 20px;
  }
}

.dropdown:hover {
  background-color: rgba(255, 255, 255, 0.6);
}

.dropdown:hover .di {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

.di ul li:hover {
  background-color: #f1f1f1
}

/* 语言栏 END*/
/*导航栏 END*/

.kenburns-top {
  -webkit-animation: kenburns-top 5s ease-out both;
  animation: kenburns-top 5s ease-out both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-4-28 14:21:8
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation kenburns-top
 * ----------------------------------------
 */
@-webkit-keyframes kenburns-top {
  0% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 16%;
    transform-origin: 50% 16%;
  }
  100% {
    -webkit-transform: scale(1.25) translateY(-15px);
    transform: scale(1.25) translateY(-15px);
    -webkit-transform-origin: top;
    transform-origin: top;
  }
}
@keyframes kenburns-top {
  0% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 16%;
    transform-origin: 50% 16%;
  }
  100% {
    -webkit-transform: scale(1.25) translateY(-15px);
    transform: scale(1.25) translateY(-15px);
    -webkit-transform-origin: top;
    transform-origin: top;
  }
}

@media screen and (max-width: 1440px) {
  /*导航栏 */
  .hl_nav {
    /*logo*/

    .logo {
      left: 50px;
    }

    /*导航栏开始*/

    .list {
      width: 960px;
      margin-left: 200px;

      .item {
        width: 120px;
      }

      .sp {
        width: 120px;
      }
    }

    .item-ul {
      li {
        margin-right: 20px;
        margin-left: 20px;
      }
    }
  }

  /*导航栏结束*/
  /*天气*/
  .TianQi {
    left: 10px !important;
  }

  /*导航栏 END*/
}

@media screen and (max-width: 1366px) {
  /*导航栏 */
  .hl_nav {
    /*logo*/

    .logo {
      left: 35px;
    }

    /*导航栏开始*/

    .list {
      width: 880px;
      margin-left: 180px;

      .item {
        width: 110px;
      }

      .sp {
        width: 110px;
      }
    }

    .item-ul {
      li {
        margin-right: 20px;
        margin-left: 20px;
      }
    }
  }

  /*导航栏结束*/
  /*导航栏 END*/
}

@media screen and (max-width: 1280px) {
  /*导航栏 */
  .hl_nav {
    /*logo*/

    .logo {
      left: 20px;
    }

    /*导航栏开始*/

    .list {
      width: 800px;
      margin-left: 160px;

      .item {
        width: 100px;
      }

      .sp {
        width: 100px;
      }
    }

    .item-ul {
      li {
        margin-right: 20px;
        margin-left: 20px;
      }
    }
  }

  /*导航栏结束*/
  /*导航栏 END*/

}
</style>
