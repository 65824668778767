const cn = {
    messages: {
        title: '椰子大观园',
        EN_title: 'Coconut Grand View Garden',
        lan: 'language',
        form:{
          f1:'名称',
          f2:'电话',
          f3:'邮箱',
          f4:'留言内容',
          f5:'提交',
          f6:'提交成功!',
          f7:'确认',
        },
        /*面包屑*/
        ban: {
            home: '首页',
            cloud: '云旅游',
            EN_cloud: 'Cloud Tourism',
            t1: '门票套餐',
            EN_t1:'Ticket Package',
            t2: '椰林商城',
            EN_t2:'Coconut Grove Mall',
            t3: '人才招聘',
            t4: '在线客服',
            t5: '投诉建议',
            EN_t5:'Complaints & Suggestions',
            t7: '宣传视频',
            t8: '在线客服',
            EN_t8: 'Online Customer Services',
            t9: '宣传视频',
            EN_t9: 'Promotion Videos',
            c1: '地图导航',
            c2: '景区地址',
            c3: '景区电话',
            c4: '地图导航',
        },
        /*七大新功能*/
        seven: {
            one: '吃',
            tow: '住',
            three: '行',
            four: '游',
            five: '购',
            six: '娱',
            seven: '学',
        },
        /*七大新功能 --END-- */

        /*页面部分*/
        /*首页*/
        index: {
            t1: '必游景点',
            EN_t1: 'Must Visits',
            t2: {
                title: '优质活动套餐',
                EN_title: 'Quality Activity Package',
                a1: '活动套餐',
                a2: '椰林商城',
                a3: '更多套餐优惠 > > >',
            },
            t3: {
                title: '交通指引',
                EN_title: 'TRAFFIC GUIDANCE',
                a1: '椰林游行',
                a2: '服务中心',
            },
            t4: {
                title: '景区动态',
                EN_title: 'News',
                p1: '景区公告',
                p2: '景区动态',
                p3: '景区活动',
                a1: '了解更多 > ',
                a2: '更多活动 > ',
                a3: '了解更多',
            },
        },
        /*首页 --END-- */
        /*底部*/
        butt: {
            p1: '欢迎关注我们',
            p2: '椰科椰子大观园',
            add: '地址：',
            tel: '电话：',
            copyright: '版权所有：',
            tit: '友情链接',
            span1: 'ICP备案序号：',
            span2: '技术支持：',
        },
        /*底部 --END--*/

        /*右侧条*/
        right: {
            p1: '公告',
            p2: '电话',
            p3: '公众号',
            p4: '微博',
            p5: '抖音',
            p6: '承载量',
        },
        /*右侧条 --END-- */
        /*其他杂项*/
        ses: {
            s1: '服务热线',
            s2: '电话号码',
            s3: '医务室电话',
            s4: '人才招聘',
            EN_s4: 'Recruitment',
            s5: '失物招领',
            EN_s5: 'Lost and Found',
            s6: '投诉建议',
            EN_s6: 'Complaints & Suggestions',
            s7: '了解详情',
            s8:'失物招领联系电话',
            s9: '失物信息',
            EN_s9: 'LOST INFORMATION',
            s10: '寻物信息',
            EN_s10: 'FOUND INFORMATION',
            c1:'已丢失的失物，请失主及时联系我们，我们将及时返还',
            c2:'如有丢失贵重物品，请及时联系我们',
            c3:'搜索职位',
            c4:'关键字',
            c5:'搜索',
            c6:'免费',
            c7:'椰子大观园旅游区最大日承载量为',
            c8:'今日客流量统计',
            c9:'明天客流量预测',
            c10:'后天客流量预测',
            a1:'观光车游览线路',
            EN_a1:'Sightseeing Bus Route',
            a2:'步行游览线路',
            EN_a2:'Walking Route',
            a3:'起点',
            a4:'人',
            a5:'导览地图',
            a6:'Navigation Map',
            a7:'下载地图',
        },
        time:{
            t1:'年',
            t2:'月',
            t3:'日',
        },
        dialog:{
            d1:'游客信息',
            d2:'姓名',
            d3:'身份证',
            d4:'联系方式',
            d5:'手机号',
            d6:'使用日期',
            d7:'选择日期',
            d8:'数量',
            d9:'入园方式',
            d10:'可凭短信、二维码或身份证入园',
            d11:'提交',
            d12:'取消',
           c1:'预定须知',
           c2:'景区门票政策',
           c3:'1、一人一票，逾期作废，不可倒卖，出售门票，概不退换；',
           c4:'2、进入园区请在规定区域吸烟、请勿使用明火；',
           c6:'3、爱护环境卫生，请不要随意丢弃废弃物、污染物，保护生态环境、爱护公共设施；',
           c7:'4、游览参观时请按照园区路线标识行走；',
           c8:'5、请勿擅自移动或损毁园区内设施和游览服务标志；',
           c9:'6、爱护园区一草一木，请勿采折花木、采集野生药材、捕捉捕杀野生动物；',
           c10:'7、未经许可禁止在园区拍摄影视作品及广告；',
           c11:'8、开园时间：8:30 闭园时间：17:30。',
           c12:'咨询电话：0898-63338299',
           c13:'救援电话：17396451178',
           c14:'投诉电话：17396453234',
           c5:'确认',
           b1:'联系人',
           b2:'金额',
           b3:'使用微信扫一扫二维码完成支付！ 确保手机号码输入正确！',
            a1:'支付成功',
            a2:'可凭短信、二维码或身份证入园请提前准备好有效证件',
            a3:'支付成功',
        }
        /*页面部分 --END-- */
    }
}
export default cn