const en = {
    messages: {
        title: 'Coconut Grand View Garden ',
        EN_title: '',
        lan: 'language',
        form:{
            f1:'Name',
            f2:'Tel',
            f3:'Email',
            f4:'Messages',
            f5:'Submit',
            f6:'Submitted successfully!',
            f7:'Confirm',
        },
        /*面包屑*/
        ban: {
            home: 'Home',
            cloud: 'Cloud Tourism',
            EN_cloud: '',
            t1: 'Ticket Package',
            EN_t1:'',
            t2: 'Coconut Grove Mall',
            EN_t2:'',
            t3: 'Recruitment',
            t4: 'Online Customer Services',
            t5: 'Complaints & Suggestions',
            EN_t5:'',
            t7: 'Promotion Videos',
            t8: 'Online Customer Services',
            EN_t8: '',
            t9: 'Promotion Videos',
            EN_t9: '',
            c1: 'Map',
            c2: 'Add',
            c3: 'Tel',
            c4: 'Map',
        },
        /*七大新功能*/
        seven: {
            one: 'Food',
            tow: 'Accommodation',
            three: 'Transport',
            four: 'Travel',
            five: 'Shopping',
            six: 'Entertainment',
            seven: 'Study',
        },
        /*七大新功能 --END-- */

        /*页面部分*/
        /*首页*/
        index: {
            t1: 'Must Visits',
            EN_t1: '',
            t2: {
                title: 'Quality Activity Package',
                EN_title: '',
                a1: 'Activity Package',
                a2: 'Coconut Grove Mall',
                a3: 'More Package Offers > > >',
            },
            t3: {
                title: 'Traffic Guide',
                EN_title: 'TRAFFIC GUIDANCE',
                a1: 'Coconut Grove Travel',
                a2: 'Service Center',
            },
            t4: {
                title: 'About Garden',
                EN_title: '',
                p1: 'Notices',
                p2: 'News',
                p3: 'Activities',
                a1: 'More > ',
                a2: 'More Activity > ',
                a3: 'More',
            },
        },
        /*首页 --END-- */
        /*底部*/
        butt: {
            p1: 'Follow Us',
            p2: 'Coconut Grand View Garden',
            add: 'Add：',
            tel: 'Tel：',
            copyright: 'Copyright：',
            tit: 'Links',
            span1: 'ICP Filing No.：',
            span2: 'Technical Support：',
        },
        /*底部 --END--*/

        /*右侧条*/
        right: {
            p1: 'Notice',
            p2: 'Telephone',
            p3: 'Official Account',
            p4: 'Weibo',
            p5: 'Douyin',
            p6: 'Carrying Capacity',
        },
        /*右侧条 --END-- */
        /*其他杂项*/
        ses: {
            s1: 'Service Hotline',
            s2: 'Tel',
            s3: 'Clinic',
            s4: 'Recruitment',
            EN_s4: '',
            s5: 'Lost and Found',
            EN_s5: '',
            s6: 'Complaints & Suggestions',
            EN_s6: '',
            s7: 'More',
            s8:'Lost and Found',
            s9: 'Found Items',
            EN_s9: 'LOST INFORMATION',
            s10: 'Lost Items',
            EN_s10: 'FOUND INFORMATION',
            c1:'Please contact us for your lost items.',
            c2:'Please contact us in time if you lose valuables.',
            c3:'Search Jobs',
            c4:'Keyword',
            c5:'Search',
            c6:'Free',
            c7:'Max. Daily Capacity of Coconut Grand View Garden',
            c8:'Today\'s Tourist Flow',
            c9:'Tomorrow\'s Tourist Flow Forecast',
            c10:'Tourist Flow Forecast of Day after Tomorrow',
            a1:'Sightseeing Bus Route',
            EN_a1:'Sightseeing bus tour route',
            a2:'Walking Route',
            EN_a2:'',
            a3:'Starting Point',
            a4:'people',
            a5:'Navigation Map',
            a6:'',
            a7:'Download the map',
        },
        time:{
            t1:'Year',
            t2:'Month',
            t3:'Day',
        },
        dialog:{
            d1:'Tourist Information',
            d2:'Name',
            d3:'ID Card',
            d4:'Contact Information',
            d5:'Phone',
            d6:'Date of Use',
            d7:'Select Date',
            d8:'Number',
            d9:'Way of Admission',
            d10:'Admission by MSM, QR Code or ID Card',
            d11:'Submit',
            d12:'Cancel',
            c1:'Rules and Regulations',
            c2:'Ticket Policy',
            c3:'1. One ticket is required for one person. Tickets are valid within the validity period, shall not be resold, and will not be refunded once sold;',
            c4:'2. Please smoke in the designated area and do not use open flames;',
            c6:'3. Please keep the environment clean and do not litter. Please protect the ecological environment and public facilities;',
            c7:'4. Please follow the route signage during visiting;',
            c8:'5. Do not move or damage the facilities or tour service signage without permission;',
            c9:'6. Please show your respect for the grasses and trees, do not pick flowers, branches or wild herbs, and do not capture or kill wild animals;',
            c10:'7. It is prohibited to shoot film or television works or advertisements in the garden without permission.',
            c11:'8. Opening Time: 8:30  Closing Time: 17:30',
            c12:'Enquiry Hotline: 0898-63338299',
            c13:'First Aid Call: 17396451178',
            c14:'Complaints Hotline: 17396453234',
            c5:'Confirm',
            b1:'Contact',
            b2:'Amount',
            b3:'Scan the QR Code with WeChat for Payment! Ensure a Correct Telephone Number!',
            a1:'Pay For Success',
            a2:'Admission by MSM, QR Code or ID Card. Please prepare valid certificates',
            a3:'Pay For Success',
        }
        /*页面部分 --END-- */
    }
}
export default en