import { ElMessage } from 'element-plus'
import qs from "qs";
import axios from 'axios'

const instance = axios.create({
  baseURL: 'https://yzdgy.hntuji.com/',
});

function getTimesTamp() {
  let timestamp = Date.parse(new Date());
  timestamp = timestamp / 1000;
  return timestamp;
}

function request(url, data, method) {
  return new Promise((resolve, reject) => {
    instance({
      method,
      url,
      data: qs.stringify(data),
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
    })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
  });
}
let post = async function (url, data) {
  const Timestamp = getTimesTamp();
  let md5Parmas = "";
  let md5ParmasObj = {
    ...data,
    Timestamp,
  };
  let md5ParmasKeys = Object.keys(md5ParmasObj).sort();
  md5ParmasKeys.forEach((item) => {
    md5Parmas = md5Parmas + `${item}=${md5ParmasObj[item]}&`;
  });

  md5Parmas = md5Parmas.slice(0, md5Parmas.length - 1);

  let md5 = await request(
      "/pwxtApi/Getencrypt",
      { type: 1, content: md5Parmas },
      "post"
  );
  //第一次MD5加密,type=1
  let desParmas = {
    ...data,
    Timestamp: Timestamp,
    Signature: md5.object,

  };
  desParmas = JSON.stringify(desParmas);

  let DES = await request(
      "/pwxtApi/Getencrypt",
      { type: 2, content: desParmas },
      "post"
  );

  //第二次DES加密,type=2
  let desContent = await request(url, { Security: DES.object }, "POST");
  desContent = desContent.substring("9");

  //第三次获取接口数据
  let content = await request(
      "/pwxtApi/Getencrypt",
      { type: 3, content: desContent },
      "post"
  );
  //第四次DES解密,type=3
  let res = JSON.parse(content.object);
  if (res.Code !== 0) {
    ElMessage.error(res.Msg);

    throw new Error(res.Msg);
  }

  return res;
};
export { post };