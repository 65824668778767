const ru = {
    messages: {
        title: 'Кокосовый Гранд Вью Гарден',
        EN_title: 'Coconut Grand View Garden',
        lan: 'language',
        form:{
            f1:'им',
            f2:'телефон',
            f3:'Почтов ящик',
            f4:'Сообщения',
            f5:'представ',
            f6:'Представлять успех!',
            f7:'подтверд',
        },
        /*面包屑*/
        ban: {
            home: 'Первая страница',
            cloud: 'Первая страница',
            EN_cloud: 'CLOUD TOURISM',
            t1: 'Пакет билетов',
            EN_t1:'TICKET PACKAGE',
            t2: 'Торговый центр кокосовой рощи',
            EN_t2:'Coconut Grove Mall',
            t3: 'Вакансия',
            t4: 'Онлайн-обслуживание клиентов',
            t5: 'Жалобы и предложения',
            EN_t5:'Complaints & Suggestions',
            t7: 'Рекламное видео',
            t8: 'Онлайн-обслуживание клиентов',
            EN_t8: 'Online Customer Services',
            t9: 'Рекламное видео',
            EN_t9: 'Promotion Videos',
            c1: 'Карта',
            c2: 'Адрес',
            c3: 'Тел',
            c4: 'Карта',
        },
        /*七大新功能*/
        seven: {
            one: 'Еда',
            tow: 'Проживание',
            three: 'Транспорт',
            four: 'Туризм',
            five: 'Покупка',
            six: 'Развлечения',
            seven: 'Изучение',
        },
        /*七大新功能 --END-- */

        /*页面部分*/
        /*首页*/
        index: {
            t1: 'Обязательно посетите',
            EN_t1: 'Must Visits',
            t2: {
                title: 'Пакет качественных мероприятий',
                EN_title: 'Quality Activity Package',
                a1: 'Спортивный пакет',
                a2: 'Торговый центр кокосовой рощи',
                a3: 'Дополнительные пакетные предложения > > >',
            },
            t3: {
                title: 'Руководство по транспорту',
                EN_title: 'TRAFFIC GUIDANCE',
                a1: 'Шесвие кокосовой рощи',
                a2: 'Сервисный центр',
            },
            t4: {
                title: 'О Гардене',
                EN_title: 'About Garden',
                p1: 'Уведомления',
                p2: 'Новости',
                p3: 'Мероприятия',
                a1: 'Более > ',
                a2: 'Больше активности > ',
                a3: 'Более',
            },
        },
        /*首页 --END-- */
        /*底部*/
        butt: {
            p1: 'Подписывайтесь на нас',
            p2: 'Кокосовый Гранд Вью Гарден',
            add: 'Адрес：',
            tel: 'Тел：',
            copyright: 'Авторские права：',
            tit: 'Ссылки',
            span1: '№ регистрации ICP：',
            span2: 'Техническая поддержка：',
        },
        /*底部 --END--*/

        /*右侧条*/
        right: {
            p1: 'Уведомление',
            p2: 'Телефон',
            p3: 'Официальный аккаунт',
            p4: 'Вейбо',
            p5: 'Доуин',
            p6: 'Пропускная способность',
        },
        /*右侧条 --END-- */
        /*其他杂项*/
        ses: {
            s1: 'Горячая линия сервиса',
            s2: 'Тел',
            s3: 'Медицинский пункт',
            s4: 'Вакансия',
            EN_s4: 'Recruitment',
            s5: 'Стол находок',
            EN_s5: 'Lost and Found',
            s6: 'Жалобы и предложения',
            EN_s6: 'Complaints & Suggestions',
            s7: 'Более',
            s8:'Стол находок',
            s9: 'Найденные',
            EN_s9: 'LOST INFORMATION',
            s10: 'Потерянные',
            EN_s10: 'FOUND INFORMATION',
            c1:'Свяжитесь с нами для ваших потерянных вещей.',
            c2:'Свяжитесь с нами вовремя, если вы потеряли ценные вещи.',
            c3:'Поиск места',
            c4:'Ключевое слово',
            c5:'поиск',
            c6:'бесплатн',
            c7:'Макимальная суточная пропускная способность турзоны Кокосовый Гранд Вью Гарден',
            c8:'туристического потока на сегодня',
            c9:'Прогноз туристического потока на завтра',
            c10:'Прогноз туристического потока на послезавтра',
            a1:'Маршрут экскурсионных автобусов',
            EN_a1:'Sightseeing Bus Route',
            a2:'Пешеходный маршрут',
            EN_a2:'Walking Route',
            a3:'Отправн точк',
            a4:'человек',
            a5:'Экскурсионный карта',
            a6:'Navigation Map',
            a7:'Скачай карту.',
        },
        time:{
            t1:'год',
            t2:'месяц',
            t3:'дне',
        },
        dialog:{
            d1:'Информация туриста',
            d2:'им',
            d3:'Удостоверение личности',
            d4:'Контакты',
            d5:'Номер телефона',
            d6:'Дата использования',
            d7:'Выберите дату',
            d8:'количеств',
            d9:'Способ входа',
            d10:'Вы можете войти, используя SMS-сообщение, QR-код или удостоверение личности.',
            d11:'представ',
            d12:'отмен',
            c1:'Памятка туризтам',
            c2:'Билетная политика',
            c3:'1. Один билет на одного человека. Билеты действительны в течение срока действия, перепродажа билетов незакона, купленные билеты возврату не подлежат.',
            c4:'2. Курите в специально отведенном месте, не пользуйтесь открытым огнем.',
            c6:'3. Содержите окружающую среду в чистоте и не мусорьте. Защищайте экологию и окружающую среду и общественное благоустройство.',
            c7:'4. Следуйте указателям маршрута во время посещения турзоны.',
            c8:'5. Не перемещайте и не повреждайте объекты или туристические знаки без разрешения.',
            c9:'6. Проявляйте уважение к травам и деревьям в турзоне, не рвите цветы и ветки деревьев, не ловите лекарственные растения, не ловите и не убивайте диких животных.',
            c10:'7. Запрещается без разрешения снимать кино, телепередачу или рекламу в турзоне.',
            c11:'8. Время открытия: 8:30, время закрытия: 17:30.',
            c12:'Телефон для получения информации: 0898-63338299',
            c13:'Вызов скорой помощи: 17396451178',
            c14:'Телефон для жалоб: 17396453234',
            c5:'подтверд',
            b1:'Контакт',
            b2:'сумм',
            b3:'Отсканируйте QR-код с помощью WeChat для оплаты! Уточните номер телефона!',
            a1:'Оплата прошла успешно',
            a2:'Вы можете войти, используя SMS-сообщение, QR-код или удостоверение личности. Пожалуйста, заранее подготовьте документы, подверждающие личность.',
            a3:'Оплата прошла успешно',
        }
        /*页面部分 --END-- */
    }
}
export default ru